<template>
  <div ref="container" class="container" :style="`width:${chartWidth}px;height:${chartHeight}px`"></div>
</template>

<script>
import { remoteLoad } from '@/common/utils'
import { DOWNLOAD_URL } from '@/api/config'
export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    demo: {
      type: Boolean,
      default: false
    },
    chartWidth: {
      type: Number,
      default: 350
    },
    title: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 180
    },
    suffix: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
  },
  mounted() {
    if (!window.CanvasJS) {
      remoteLoad(`${DOWNLOAD_URL}static/js/canvasjs.min.js`).then(this.renderCanvasJs)
    }
  },
  watch: {
    items() {
      this.renderCanvasJs()
    }
  },
  methods: {
    renderCanvasJs() {
      let dataPoints = this.items.map(k => {
        return {
          label: k.name,
          y: k.sales
        }
      })

      if (!window.CanvasJS) {
        return
      }

      window.CanvasJS.addColorSet("customColorSet1",
      [//colorSet Array
        "#67C23A",
        "#FA5555",
        "#3EA0DD",
        "#23BFAA",
        "#B08BEB",
        "#667AE6",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6"
      ]);
      let chart = new window.CanvasJS.Chart(this.$refs.container, {
          colorSet: "customColorSet1",
          animationEnabled: true,
          exportEnabled: true,
          //theme: "light1", // "light1", "light2", "dark1", "dark2"
          backgroundColor: '#F4F4F8',
          title: {
            text: this.title
          },
          axisY: {
            includeZero: false,
            suffix: this.suffix,
            labelFontSize: 13
          },
          axisX: {
            labelFontSize: 13
          },
          data: [{
              type: "column", //change type to bar, line, area, pie, etc
              //indexLabel: "{y}", //Shows y value on all Data Points
              indexLabelFontColor: "#5A5757",
              indexLabelPlacement: "outside",
              dataPoints
          }]
      });
      chart.render();
    }
  }
}
</script>

<style lang="scss" scoped>
.container ::v-deep {
  .canvasjs-chart-toolbar {
    right: -7px !important;
    top: -7px !important;
    button {
      background-color: #F4F4F8 !important;
      &:hover {
        background-color: #CC3333 !important;
      }
    }
  }
  .canvasjs-chart-credit {
    display: none !important;
  }
}
</style>
